









import profile from '@/views/common/settings/main.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: { profile }
})

export default class CoachIndex extends Vue { }
